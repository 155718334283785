@import '../../styles/variables.scss';

.innerContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 50px;
  gap: 20px;
  width: 60%;
  height: 70%;
  background-color: rgb(249, 252, 244);
  border-radius: 20px;
  box-shadow: 0px 0px 20px 5px $primeryColor;
}

button {
  color: white;
}

.sideImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 10px;
  width: 25%;
  img {
    width: 40px;
    height: 40px;
    border: 1px solid $primeryColor;
    border-radius: 50%;
  }
}

.mainImage {
  img {
    width: auto;
    height: 300px;
    border: 2px solid $primeryColor;
  }
}
