@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: 2rem;
  font-family: Inter;

  .heading {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 11px 0.5rem;
    font-weight: 500;
    font-size: 20px;
    border-bottom: 1px solid $borderColor;
  }

  .name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 50%;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $greyStroke;
    }

    input:focus {
      outline: none;
      border-bottom: 1px solid $greyStroke;
    }

    .imageContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      .profileImg {
        border: 3px solid #26b893;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
      }

      .changePhoto {
        display: flex;
        align-items: center;
        background-color: $primeryColor;
        border-radius: 50%;
        padding: 6px;
        position: absolute;
        bottom: 20px;
        right: 5px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 80%;

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      width: 100%;
      font-size: 14px;

      input {
        border: none;
        border-bottom: 2px solid $greyStroke;
        box-sizing: border-box;
        border-radius: 2px;
        height: 100%;
        width: 80%;
      }

      select {
        outline: none;
        // width: 100%;
        border: none;
        border-bottom: 1px solid $greyStroke;
        padding-right: 40px;

        option {
          background-color: $primeryColor;
          color: #fff;
        }
      }

      .address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        font-size: 14px;
      }

      input:focus {
        outline: none;
        box-sizing: border-box;
      }
    }
  }

  button {
    width: 50%;
    padding: 0.5rem 1rem;
    background-color: $primeryColor;
    margin: 0px auto;
    color: white;
    font-weight: 500;
    border-radius: 20px;
    font-size: 16px;
  }
}
