// color variables

$primeryColor: #3b7358;
$textHeading: #1c1c1c;
$textgrey: #9c9c9c;
$textPara: #484964;
$lightGreen: #e4f3ec;
$midLightGreen: #caeadc;
$greenstroke: #b7dbcc;
$greyStroke: #eeeef2;
$cardGrey: #f9f9fcb4;
$cardBlue: #e3f5ff;
$cardPurple: #e5ecf6;
$whiteColor: #ffffff;
$borderColor: #e7e7e7;

// font variables

$fs24: 24px;
$fs16: 16px;
$fs14: 14px;
$fs12: 12px;
