@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.DBdataGrid {
  width: 100%;
  thead {
    tr {
      th {
        font-weight: 700;
        font-size: 14px;
        padding: 8px;
        border-bottom: 1px solid $greyStroke;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: $cardGrey;
      }
      td {
        font-weight: 400;
        font-size: 14px;
        padding: 8px;
        border-bottom: 1px solid $greyStroke;
      }
    }
  }

  .noAppointment {
    color: red;
  }
}
