@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.dashboardLeft {
  @include flex-coloumn-center-start;
  width: 15%;
  border-right: 1px solid $greyStroke;
  height: 100%;
  padding: 20px 10px;

  .logo a img {
    width: 130px;
  }

  .sidebar {
    width: 100%;
    .sidebarHeading {
      color: $textgrey;
      text-align: left;
      width: 100%;
      display: inline-block;
      font-weight: 600;
      padding: 10px 10px;
    }
    .sidebarList {
      width: 100%;
      .sidebarListItem {
        width: 100%;
        background-color: transparent;
        border-radius: 12px;
        padding: 8px 8px;

        a {
          @include flex-start-center;
          width: 100%;

          span:first-child i {
            color: $primeryColor;
          }

          span:last-child {
            color: $textHeading;
            font-size: $fs14;
            font-weight: 400;
          }
        }
      }

      .sidebarListItem.active {
        background-color: $lightGreen;
      }
    }
  }
}
