@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.packageMain {
  @include flexProp(row, flex-start, center, 20px, 0px, 0px, 0px, 0px);
  flex-wrap: wrap;
  max-height: 690px;
  overflow-y: auto;

  .packageBox {
    width: 32%;
    background-color: $whiteColor;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px;

    h5 {
      font-size: 20px;
      margin-top: 10px;
      color: $primeryColor;
    }

    img {
      width: 100%;
    }

    .packageDetail {
      .packageDetailItem {
        @include flexProp(row, flex-start, center, 5px, 10px, 0px, 10px, 0px);

        .packageDetailIcon {
          @include flexProp(row, center, center, 0px, 0px, 0px, 0px, 0px);
          width: 26px;
          height: 26px;
          background-color: $lightGreen;
          border-radius: 8px;

          min-width: 26px;

          i {
            color: $primeryColor;
          }
        }

        span {
          p {
            color: $textPara;
          }
        }
      }
    }
  }
}
