@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.heading {
  color: #292929;
  font-weight: 700;
  font-size: 30px;
}

.subHeading {
  color: #545454;
  font-weight: 400;
  font-size: 16px;
}

.primaryBtn {
  width: 100%;
  background-color: $primeryColor;
  border-radius: 8px;
  color: #ffffff;
  padding: 0.5rem 0rem;
  font-weight: 400;
  text-align: center;
}

.outerContainer {
  width: 100vw;
  height: 100vh;
  background-color: $primeryColor;
  display: flex;
  align-items: center;
  justify-content: center;

  .innerContainer {
    width: 70vw;
    height: 80vh;
    background-color: #fffefc;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 4rem;

    .leftContainer {
      width: 40%;
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 40%;
      }

      .headingContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: Inter;
        height: 22%;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        position: relative;
        height: 35%;

        input {
          border: 1px solid $greyStroke;
          border-radius: 10px;
          width: 100%;
          padding: 0.3rem 0.5rem;
        }

        input:focus {
          outline: none;
          border: 1px solid #8ac0ff;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 0.3rem 0.5rem;
        }

        .forgot {
          color: #545454;
          font-weight: 400;
          font-size: 14px;
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }

    .rightContainer {
      width: 40%;
      height: 85%;
      img {
        width: 100%;
      }
    }
  }
}

.forgotLeftContainer {
  width: 40%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 40%;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Inter;
    height: 22%;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    position: relative;
    height: 35%;

    input {
      border: 1px solid $greyStroke;
      border-radius: 10px;
      width: 100%;
      padding: 0.3rem 0.5rem;
    }

    input:focus {
      outline: none;
      border: 1px solid #8ac0ff;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0.3rem 0.5rem;
    }

    .forgot {
      color: #545454;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
}

.verificationLeftContainer {
  width: 45%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 40%;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Inter;
    height: 22%;
  }

  .inputContainer {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 35%;
    width: 100%;

    input {
      border: 1px solid #8ac0ff;
      border-radius: 10px;
      width: 10%;
      padding: 0.3rem 0.5rem;
    }

    input:focus {
      outline: none;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0.3rem 0.5rem;
    }

    .forgot {
      color: #545454;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
}

.verificationInputContainer {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 35%;
  width: 100%;

  input {
    border: 1px solid #8ac0ff;
    border-radius: 10px;
    width: 10%;
    padding: 0.3rem 0.5rem;
    text-align: center;
  }

  input:focus {
    outline: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
  }

  .forgot {
    color: #545454;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.finishInnerContainer {
  width: 70vw;
  height: 80vh;
  background-color: #fffefc;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem;
  gap: 25px;

  h6 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
  }
  .finishButtonContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
