@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
  height: 75vh;
  width: 35vw;
  font-style: Inter;

  .subSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    span {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 20px;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    // border: 2px solid red;

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo {
        display: flex;
        gap: 10px;

        h6 {
          font-size: 14px;
          font-weight: 400;
          color: #a3aed0;
        }
      }

      h5 {
        margin-left: 28px;
        font-size: 15px;
      }
    }

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
    }

    .rightSection {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 20px;
    }
  }

  // .aadharSection{
  //     display: flex;
  //     gap: 20px;

  //     div{
  //         border: 2px solid red;
  //         height: 100px;
  //         width: 140px;
  //         border-radius: 5px;
  //     }
  // }

  .photoSection {
    display: flex;
    gap: 20px;

    div {
      width: 80px;
      height: 80px;
      border: 2px solid red;
      border-radius: 5px;

      img {
        width: 100%;
      }
    }
  }

  .buttons {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;

    button {
      border: none;
      color: #fff;
      border-radius: 50px;
      font-weight: 500;
      padding: 5px 30px;
      font-size: 17px;
    }

    .approve {
      background-color: $primeryColor;
    }

    .cancel {
      background-color: red;
    }
  }
}
