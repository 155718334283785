@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

::-webkit-input-placeholder {
  font-size: 14px;
}

.pageHeader {
  @include flexProp(row, space-between, center, null, 10px, 10px, 10px, 10px);
  width: 100%;
  background-color: $lightGreen;
  .pageHeaderRight {
    @include flexProp(
      row,
      flex-start,
      flex-start,
      10px,
      null,
      null,
      null,
      null
    );

    .pageHeaderHeading {
      font-weight: 700;
    }

    .inputBox {
      position: relative;

      input {
        background-color: $midLightGreen;
        border: 1px solid $greenstroke;
        border-radius: 6px;
        padding-left: 30px;
      }

      .inputIcon {
        position: absolute;
        left: 10px;
        top: 1px;
        i {
          font-size: 14px;
          color: $primeryColor;
        }
      }
    }

    .filterIcon {
      @include flexProp(row, center, center, null, null, null, null, null);
      width: 28px;
      height: 28px;
      background-color: $midLightGreen;
      border: 1px solid $greenstroke;
      border-radius: 6px;

      i {
        color: $primeryColor;
      }
    }
  }

  .pageHeaderLeft {
    display: flex;
    gap: 20px;
    p {
      font-size: 14px;
      font-weight: 600;
      color: $textgrey;
    }
    span {
      color: $textHeading;
    }
  }
}
