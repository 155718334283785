@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.searchInputContainer {
  display: flex;
  align-items: center;
  background-color: $midLightGreen;
  border-radius: 6px;
  padding: 0 5px;

  input {
    border: 0px;
    background-color: $midLightGreen;
    width: 100%;
    // border-radius: 10px;
    // padding: 2px 5px;
  }

  input:focus {
    outline: none;
    border: none;
  }

  input:hover {
    border: none;
  }
}

.ratingBox {
  width: 80%;
  height: 24px;
  background-color: $midLightGreen;
  border-radius: 50px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  color: #3b7358;

  span {
    font-size: 12px;
    width: 500%;
  }

  input {
    border: 2px solid #7fc9a5;
    accent-color: $primeryColor;
  }
}

.priceBox {
  display: flex;
  border: 2px solid #919191;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  border-radius: 50px;
  padding: 5px 5px;
  font-size: 12px;

  input {
    border: 0px;
    // background-color: $midLightGreen;
    width: 100%;
    // border-radius: 10px;
    // padding: 2px 5px;
  }

  input:focus {
    outline: none;
    border: none;
  }

  input:hover {
    border: none;
  }

  h6 {
    color: #3b7358;
    font-weight: 700;
    font-size: 12px;
  }
}

.priceSelectBox {
  width: 100%;
  background-color: $midLightGreen;
  border-radius: 6px;
  border: none;
  padding: 5px 5px;
  font-size: 14px;
  color: #3b7358;
  margin: 10px 0px;
}

.primaryBtn {
  width: 100%;
  background-color: $primeryColor;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  font-family: Inter;
  text-align: center;
}

.secondaryBtn {
  width: 100%;
  border: 2px solid $primeryColor;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 16px;
  color: $primeryColor;
  font-weight: 500;
  padding: 5px 10px;
  font-family: Inter;
  text-align: center;
}
