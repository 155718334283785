@import '../../styles//mixins.scss';
@import '../../styles/variables.scss';

.dashboardRight {
  width: 20%;
  border-left: 1px solid $greyStroke;
  height: 100%;
  padding: 0px 0px;

  .notificationBox {
    h5 {
      font-size: 16px;
      color: $textHeading;
      padding: 14px 10px 14px 5px;
      font-weight: 600;
    }

    .notificationList {
      .notificationListItem {
        @include flexProp(
          row,
          flex-start,
          flex-start,
          10px,
          5px,
          5px,
          5px,
          5px
        );
        margin: 5px 0px;
        padding: 5px 10px;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $lightGreen;
        }
        h6 {
          font-size: 14px;
          font-weight: 400;
        }
        p {
          font-size: 14px;
        }
        .notificationIcon {
          @include flexProp(row, center, center, 0px, 0px, 0px, 0px, 0px);
          background-color: $lightGreen;
          border-radius: 6px;
          width: 26px;
          height: 26px;
          i {
            color: $primeryColor;
          }
        }
      }
    }
  }
}
