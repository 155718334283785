@mixin flexProp(
  $direction,
  $justify,
  $align,
  $gap,
  $paddingTop,
  $paddingRight,
  $paddingBottom,
  $paddingLeft
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
  padding: $paddingTop $paddingRight $paddingBottom $paddingLeft;
}

// to center any object when the parent div or element have position "relative"
@mixin centerElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin button {
  background: $bgcolor;
  padding: 8px 15px;
  border-radius: 36px;
  width: fit-content;
  display: inline-block;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-coloumn-center-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
