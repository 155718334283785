@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $greyStroke;
}

input:focus {
  outline: none;
  border-bottom: 1px solid $greyStroke;
}

.outerCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
  width: 100vh;
  border: 2px solid $greyStroke;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.5rem 1rem;
  font-style: Inter;

  .cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    .leftSide {
      height: 90%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;

      .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .profileImageContainer {
          position: relative;
          width: 150px;
          height: 150px;
          border: 1px solid $primeryColor;
          border-radius: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }

        .changePhoto {
          display: flex;
          align-items: center;
          background-color: $primeryColor;
          border-radius: 50%;
          padding: 6px;
          position: absolute;
          bottom: 10px;
          right: 5px;
        }

        .details {
          text-align: center;
          width: 100%;

          h2 {
            font-size: 24px;
            font-weight: 500;
          }

          .location {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;

            h6 {
              color: #a3aed0;
              font-size: 16px;
            }
          }

          input {
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            // width: 50%;
          }
        }
      }
      .lowerBox {
        display: flex;
        justify-content: center;
        gap: 30px;

        .child {
          text-align: center;

          span {
            color: #a3aed0;
            font-size: 14px;
            font-weight: 400;
          }

          h6 {
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
    }

    .line {
      height: 90%;
      background-color: $greyStroke;
      width: 2px;
      align-items: flex-start;
    }

    .rightSide {
      overflow-y: scroll;
      height: 90%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      gap: 15px;

      h2 {
        font-size: 20px;
      }

      .detailsBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .logo {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        span {
          font-weight: 500;
          color: rgba(28, 28, 28, 0.4);
          font-size: 14px;
        }

        .rightDescription {
          margin-left: 25px;
          font-size: 14px;
          font-weight: 400;
        }

        .address {
          font-size: 14px;
        }

        input {
          margin: 0px 25px;
          font-size: 14px;
          padding: 0px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .cancel {
      padding: 0.2rem 0.8rem;
      font-weight: 500;
      border-radius: 50px;
      border: 1px solid $primeryColor;
      color: $primeryColor;
    }

    .location {
      font-weight: 500;
      padding: 0.2rem 0.8rem;
      background-color: #e4f3ec;
      border: 1px solid $primeryColor;
      border-radius: 50px;
      color: $primeryColor;
    }
    .dashboard {
      padding: 0.2rem 0.8rem;
      background-color: $primeryColor;
      border-radius: 50px;
      color: #ffffff;
      font-weight: 600;
    }
  }
}
